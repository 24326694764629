import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoSrcURL from "../images/op.m4a"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div class="overflow-hidden">
      <StaticImage
        src="../images/scene1.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      />
      <div class="p-6 sm:p-10 shadow-2xl leading-8">
        <p className="text-lg font-bold">ショップリフレッシュ</p>
        <p>本社・旭川ショップをリフレッシュし、心地よい暮らしを表現する「18のライフスタイルシーン」の空間が登場。その様子を皆さまにお伝えいたします。</p>
        {/* image begins */}
        <div className="">
          <p>1F＜エントランス＞</p>
          <div className="sm:grid sm:grid-cols-3">
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/entrance1.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/entrance2.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
          </div>
          <p>3F Shop</p>
          {/* grid */}
          <div className="sm:grid sm:grid-cols-3">
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene01.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene02.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene03.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene04.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene05.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene06.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene07.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene08.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene09.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene10.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene11.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene12.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene13.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene14.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene15.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene16.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene17.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/scene18.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
          </div>
          <p>バーチャル旭川ショップ</p>
          <div class="sm:px-3 shadow-2xl leading-8" data-sal="slide-up"  data-sal-delay="500"  data-sal-easing="ease">
            <iframe width='100%' height='480' src='https://my.matterport.com/show/?m=tQk4KDTkJAe' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>        
          </div>
          <p>3F〈ラボ・ギャラリー〉</p>
          <div className="sm:grid sm:grid-cols-3">
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/entrance1.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/entrance2.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/entrance2.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
          </div>
          <p>5F〈ヒストリールーム〉</p>
          <div className="sm:grid sm:grid-cols-3">
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/history1.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/history2.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden sm:px-3"><StaticImage
              src="../images/history3.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* san dining section starts */}
    <div class="overflow-hidden">
      <StaticImage
        src="../images/newmodel.jpg"
        alt="CondeHouse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      />
      <div class="p-6 sm:p-10 shadow-2xl leading-8" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease">
        <p className="text-lg font-bold">New Collection Debut</p>
        <p>道産広葉樹である北海道カバの無垢材を使用した「サンダイニング」や、㈱メーベルトーコー社との共同開発の新収納シリーズ、ワークスペースで活躍するワイヤリングシステムを搭載した無垢テーブルなど、ホームユースのみならず公共施設やオフィス、レストランなど幅広い空間に向けた新作を発表いたします。</p>
      </div>
    </div>
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-2">
      <div className="overflow-hidden">
        <StaticImage
          src="../images/san.jpg"
          alt="CondeHouse Style 2021 Scene Image 1"
          style={{ marginBottom: `1.45rem`, width: '100%' }}
          className="topimg magnify"
        />
      </div>
      <div className="px-5">
        <p className="font-bold text-base">SAN サンダイニング <br/>Design: Michael Schneider</p>
        <p className="leading-8">ドイツ人デザイナーのミヒャエル・シュナイダー氏による洗練されたデザインが特長。地元、北海道広葉樹のカバ材を使用した住宅から店舗オフィスまで活躍する、新作チェアーを発表いたします。</p>
      </div>
    </div>
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-3">
      <div className="overflow-hidden sm:px-3"><StaticImage
        src="../images/san1.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden sm:px-3"><StaticImage
        src="../images/san2.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden sm:px-3"><StaticImage
        src="../images/san3.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
    </div>
    <p className="text-center"><Link to="/18scenes" className="ch-button shadow-lg">→ 製品情報はこちら</Link></p>
    {/* san dining section ends */}
     {/* A+ section starts */}
    <div className="p-6 sm:p-10 sm:grid sm:grid-cols-2">
      <div className="overflow-hidden">
      <StaticImage
        src="../images/system.jpg"
        alt="CondeHouse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem`, width: '100%' }}
        className="topimg magnify"
      />
      </div>
      <div className="px-5">
      <p className="font-bold text-base">Select Furniture A+ <br />セレクトファニチャー A+（エープラス）</p>
        <p className="leading-8">システムファニャーamが生まれ変わります。メーベルトーコー社との共同開発で生まれた 新モデルを発表。新たな仕様を加え、数ある組み合わせからお好みに合わせ、簡単に選べるセレクトファニチャーへと進化しました。</p>
      </div>
    </div>
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-3">
      <div className="overflow-hidden sm:px-3"><StaticImage
        src="../images/a1.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden sm:px-3"><StaticImage
        src="../images/a2.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden sm:px-3"><StaticImage
        src="../images/a3.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
    </div>
    <p className="text-center"><Link to="/18scenes" className="ch-button shadow-lg">→ 製品情報はこちら</Link></p>
    {/* A+ section ends */}
    {/* One section starts */}
       <div className="p-6 sm:p-10 sm:grid sm:grid-cols-2">
      <div className="overflow-hidden">
      <StaticImage
        src="../images/onetop.jpg"
        alt="CondeHouse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem`, width: '100%' }}
        className="topimg magnify"
      />
      </div>
      <div className="px-5">
      <p className="font-bold text-base">ONE ワン ダイニング テーブル<br />Design: 倉本 仁</p>
        <p className="leading-8">出会った瞬間から心惹かれ、見てふれて納得するものを目指すという、デザイナー倉本仁氏と開発した丸テーブルに、小判形のテーブル2サイズを追加いたします。横長ながら一本脚はそのままに、様々な空間における設置が可能となりました。食事やミーティングなど様々な場面での選択の幅が広がります。</p>
      </div>
    </div>
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-3">
      <div className="overflow-hidden sm:px-3"><StaticImage
        src="../images/one1.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden sm:px-3"><StaticImage
        src="../images/one2.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden sm:px-3"><StaticImage
        src="../images/one3.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
    </div>
    <p className="text-center"><Link to="/18scenes" className="ch-button shadow-lg">→ 製品情報はこちら</Link></p>
    {/* A+ section ends */}
    <div className="overflow-hidden" >   
      <div className="flex overflow-hidden">
        <StaticImage
          src="../images/02.jpg"
          alt="CondeHouse New System Furniture"
          style={{ marginBottom: `1.45rem`, width: '100%' }}
          className="topimg magnify"
        />
        <hr />
        <StaticImage
          src="../images/ten.jpg"
          alt="CondeHouse Style 2021 Scene Image 1"
          style={{ marginBottom: `1.45rem`, width: '100%' }}
          className="topimg magnify"
        />
      </div>

      <div class="p-6 sm:p-10 leading-8" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease">
        <p>その他、今春発売を開始した新作SLテーブルや人気のテンシリーズの追加アイテムなど、住空間からオフィス空間まで、様々な場面で活躍するアイテムをご提案いたします。</p>
      </div>
      <div class="overflow-hidden">
        <StaticImage
          src="../images/factorytour.jpg"
          alt="CondeHouse Style 2021 Scene Image 1"
          style={{ marginBottom: `1.45rem` }}
          className="topimg magnify"
        />
        <div class="p-6 sm:p-10 shadow-2xl leading-8" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease">
          <p className="text-lg font-bold">バーチャルファクトリーツアー</p>
          <p>カンディハウスのロングセラーアイテム「ルントオム チェアー（座張）」製作における「製材・突板加工」から最終工程の「座板取付・出荷前検査」までを動画でご紹介。迫力あるものづくりの様子をぜひお楽しみください。</p>
        </div>
        {/* Youtube Links */}
        <div class="p-6 sm:p-10 videobox">
         <iframe width="100%" height="400" src="https://www.youtube.com/embed/MucLe7HK2RY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="p-6 sm:p-10 small-videos sm:grid sm:grid-cols-2">
          <div class="videobox">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/piYRHoMNlms" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <p>①Factory</p>
          </div>
          <div class="videobox">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/RxdpkhV_KeM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <p>②製材・突板加工</p>
          </div>
          <div class="videobox">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/piYRHoMNlms" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <p>③木工加工</p>
          </div>
          <div class="videobox">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/RxdpkhV_KeM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <p>製材・突板加工</p>
          </div>
          <div class="videobox">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/piYRHoMNlms" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <p>Factory</p>
          </div>
          <div class="videobox">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/RxdpkhV_KeM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <p>製材・突板加工</p>
          </div>
          <div class="videobox">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/piYRHoMNlms" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <p>Factory</p>
          </div>
          <div class="videobox">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/RxdpkhV_KeM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <p>製材・突板加工</p>
          </div>
          <div class="videobox">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/piYRHoMNlms" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <p>Factory</p>
          </div>
          <div class="videobox">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/RxdpkhV_KeM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <p>製材・突板加工</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
